
const ACCEPTED_COOKIES_KEY = 'accepted-cookies';

function cookiesBanner(window) {
  const acceptedCookies = localStorage.getItem(ACCEPTED_COOKIES_KEY) || false;
  if (!acceptedCookies) {
    const cookiesAcceptModal = document.querySelector('#modal-cookies');
    const cookiesAcceptButton = document.querySelector('#cookies-accept-modal');
    const cookiesAcceptButtonModal = document.querySelector('#cookies-accept-modal');

    if (cookiesAcceptButton) {
      cookiesAcceptButton.addEventListener('click', event => {
        localStorage.setItem(ACCEPTED_COOKIES_KEY, true);
        cookiesAcceptModal.style.display = 'none';
      });
    }

    if (cookiesAcceptModal) {
      cookiesAcceptModal.style.display = 'block';
    }

    if (cookiesAcceptButtonModal) {
      cookiesAcceptButtonModal.addEventListener('click', event => {
        localStorage.setItem(ACCEPTED_COOKIES_KEY, true);
        cookiesAcceptModal.style.display = 'none';
      });
    }
  }
}

export default cookiesBanner;
